#info-development {
  background: url(../assets/images/info-development-bg.png) no-repeat;
  background-size: 100% 100%;
  height: 90vh;
  /* background-position: 0; */
}


@media only screen and (max-height: 700px) {
  #info-development {
    height: 35rem;
  }
}

@media only screen and (max-width: 1023px) {
  #info-development {
    height: 35rem;
    background-size: 190% 190%;
    background-position: -93px -307px;  
  }
}

@media only screen and (max-width: 767px) { 
  #info-development {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #info-development {
    background: url(../assets/images/info-development-bg-mobile.png) no-repeat;
    background-size: cover;
  }
}