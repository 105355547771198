.logo {
  width: 21rem;
  height: 6rem;
}
.text-top {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

$btn-width: 35rem;
$btn-height: 5rem;
$btn-border-radius: 1.5rem;

.top {
  &__bg {
    background-image: url(../assets/images/top-bg.png);
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &__title {
    width: 80%;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__subtitle {
    width: 53rem;
    color: #fff;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 1.5rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 90%;
    justify-content: center;
  }

  &__button {
    cursor: pointer;
    max-width: 35rem;
    width: 100%;
    height: 5rem;
    color: #fff;
    font-size: 2rem;

    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #816701;
    border-radius: 1.5rem;
    border: 0 solid;
    outline: 1px solid;

    outline-color: #816701;
    outline-offset: 0px;
    text-shadow: none;
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    &__position {
      display: flex;
      position: relative;
      justify-content: center;
      bottom: 2.6rem;
      z-index: 4;
    }
  }
}
.top__button:hover {
  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  .logo {
    margin: 1rem 0rem;
    width: 22rem;
    height: 5rem;
  }
  .top {
    &__bg {
      background-image: url(../assets/images/top-bg.png);
      width: 100%;
      height: 52rem;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
    }

    &__title {
      width: 80%;
      color: #fff;
      font-style: normal;
      line-height: normal;
      /* font-size: 3rem; */
      text-align: left;
    }

    &__subtitle {
      width: 54rem;
      color: #fff;
      font-style: normal;
      line-height: normal;
      // font-size: 1rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 7rem;
    }
  }
}

@media only screen and (max-width: 1400px) {
  $btn-width: 28rem;
  $btn-height: 3.5rem;
  $btn-border-radius: 1rem;

  .top {
    &__title {
      width: 50%;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 1.5rem;
      margin-left: 5rem;
    }

    &__button {
      width: $btn-width;
      height: $btn-height;
      border-radius: $btn-border-radius;
      font-size: 1.4rem;
      align-items: center;
      justify-content: center;

      &__position {
        bottom: 1.5rem;
      }
    }

    &__subtitle {
      width: 50%;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      margin-left: 5rem;
      font-size: 1rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 3rem;
    }

    &__bg {
      background-image: url(../assets/images/top-bg.png);
      width: 100%;
      height: 93vh;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
    }
  }

  .info__images {
    width: 3rem !important;
    height: 3rem !important;
  }

  .info__project__container h2 {
    font-size: 1rem !important;
  }

  .logo {
    width: 14rem;
    height: 3rem;
    margin: 0 0rem 0rem 4rem;
  }

  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) {
  .logo {
    margin: 1rem 3rem;
    width: 16rem;
    height: 3rem;
  }
  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .top {
    &__bg {
      background-image: url(../assets/images/top-bg.png);
      width: 100%;
      height: 55rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      /* background-position: -66rem 0; */
    }

    &__title {
      width: 90%;
      font-size: 1.75rem;
      text-align: left;
    }

    &__subtitle {
      width: 90%;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 1rem;
      text-align: left;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 3rem;
    }

    &__button {
      width: 23rem;
      height: 3.5rem;
      border-radius: 0.5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    margin: 0 1rem 3rem 1rem;
    width: 12rem;
  }
  .text-top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  $btn-width: 14rem;
  $btn-height: 2.5rem;
  $btn-border-radius: 0.5rem;
  
  .top {
    &__bg {
      background-image: url(../assets/images/top-bg.png);
      width: 100%;
      height: 40rem;
      background-repeat: no-repeat;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: 100%;
    }

    &__title {
      width: 90%;
      font-size: 1.325rem;
      text-align: center;
      margin-left: 0;
    }

    &__subtitle {
      width: 90%;
      color: #fff;
      font-size: 0.8rem;
      text-align: center;
      margin: 0 auto;
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      gap: 0px;
    }

    &__button {
      width: $btn-width;
      height: $btn-height;
      border-radius: $btn-border-radius;
      font-size: 0.9rem;

      &__position {
        bottom: 1.25rem;
      }
    }
  }
}

