#info-release {
  background: url(../assets/images/info-release-bg.png) no-repeat;
  background-size: 100% 100%;
  /* display: flex; */
  /* background-position: 0; */
  height: 90vh;
}

@media only screen and (max-height: 700px) {
  #info-release {
    height: 40rem;
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1023px) {
  #info-release {
    height: 35rem;
    background-size: 1440px 800px;
    background-position: -300px -163px;
  }
}

@media only screen and (max-width: 767px) {
  #info-release {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #info-release {
    /* background-position: -500px -163px;   */
    background: url(../assets/images/info-release-bg-mobile.png) no-repeat;
    background-size: cover;
  }
}
