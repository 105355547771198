#numbered-info {
  padding: 55px 10px;
  height: 60rem;
  background: linear-gradient(141.3deg, #000000 -32.85%, rgba(0, 0, 0, 0.513514) 20.24%, rgba(0, 0, 0, 0) 54.37%);
}

.numbered-box-containers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
}

.numbered-box-container {
  display: flex;
  justify-content: center;
  gap: 80px;
}

$cyan: #0505A9;
$dark: #FFFFFF;
$borderW: 2px;

.numbered-box {
  --circle-size: 75px;
  width: 100%;
  max-width: 430px;
  height: 240px;
  position: relative;
  border: 2px solid #fff;
  border-radius:  6px;
  $numOfBlobs: 4;
  z-index: 0;
  position: relative;
  outline: none;
  transition: 0.3s ease;
  color: #fff;
  padding: 0 16px;

  /**** Animation content ****/
  &:before {
    content: "";
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  &:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: $borderW*1.5;
    top: $borderW*1.5;
    width: 100%;
    height: 100%;

    transition: all 0.3s 0.2s;
    border-radius:6px;
  }
  
  &:hover {
    color: $dark;
    transform: scale(1.1);

    &:after {
      transition: all 0.3s;
      left: 0;
      top: 0;
      border-radius: 6px;
    }
  }
  
  &__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* border-radius:30px; */
    border-radius: 6px;
    background: #214264;
  }
  
  // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
  &__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
    border: 0px;
  }
  
  &__blob {
    position: absolute;
    top: $borderW;
    width: calc(100% / $numOfBlobs);
    height: 100%;
    background: #B57900;
    border: 0px;
    border-radius: 100%;
    transform: translate3d(0,150%,0) scale(1.7);
    transition: transform 0.45s;
    
    @supports(filter: url('#goo')) {
      transform: translate3d(0,150%,0) scale(1.4);
    }
    
    @for $i from 1 through $numOfBlobs {
      &:nth-child(#{$i}) {
        left: calc(calc($i - 1) * calc(120% / $numOfBlobs));
        border: 0px;
        transition-delay: ($i - 1) * 0.08s;
      }
    }
    

    .numbered-box:hover & {
      transform: translateZ(0) scale(1.7);
      
      @supports(filter: url('#goo')) {
        transform: translateZ(0) scale(1.5);
        border: 0px;
      }
    }
  }
/**** Animation content end ****/

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    font-size: 3rem;
    font-family: "SF Pro Display";
    font-weight: 600;
    background-color: #fff;
    color: #B57900;
    border: 1px solid #214264;

    position: absolute;
    left: 0;
    right: 0;
    top: calc(-1 * var(--circle-size) / 2);
    margin: 0 auto;
  }

  &__title {
    margin-top: calc(var(--circle-size) / 1.5);
    font-size: 1.8rem;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  &__text {
    text-align: center;
    margin-top: 18px;
    font-size: 600;
    position: relative;
    z-index: 2;
    font-size: 1.1rem;
  }
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  #numbered-info {
    height: 60rem;
  }
}

@media only screen and (max-width: 1023px) { 
  #numbered-info {
    height: 100%;
    
  }

  .numbered-box-containers-wrapper {
    gap: 45px;
  }

  .numbered-box-container {
    flex-direction: column;
    gap: 45px;
  }

  .numbered-box {
    padding: 0 12px;
    height: 275px;

    &:hover {
      transform: scale(1.06);
    }

    &__title {
      font-size: 1.6rem;
    }
  
    &__text {
      font-size: .975rem;
    }
  }
}
