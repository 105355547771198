#mobile {
  background-image: url(../assets/images/mobile-bg.png);
  background-size: cover;
  width: 100%;
  height: 95vh;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  &__title {
    color: #fff;
    text-align: center;
    padding: 40px;
    padding-top: 60px;
  }

  &__img {
    width: 26rem;
    height: 25rem;
  }

  &__container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & a {
      > img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  &__btn {
    width: 5rem;
    height: 5rem;
  }
  &__btn.prev {
    background: url(../assets/svg/arow-left.svg) no-repeat;
    background-size: contain;
  }

  &__btn.next {
    background: url(../assets/svg/arow-right.svg) no-repeat;
    background-size: contain;
  }
}

#body {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.swiper {
  padding-bottom: 60px !important;
  width: 100%;
  height: 45rem;
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    border-radius: 5px;
    width: 1rem;
    height: 10rem !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    &.active {
      height: 22rem !important;
    }
  }
  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    opacity: 0.65;
    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 10rem;
      height: 3rem;
    }
    svg path {
      fill: #fff;
    }
  }
  .prev {
    width: 28rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .next {
    right: 0;
    width: 28rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}


.mob-button {
  cursor: pointer;
  max-width: 28rem;
  width: 100%;
  height: 4rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #816701;
  border-radius: 1.5rem;
  border: 0 solid;
  outline: 1px solid;

  outline-color: #816701;
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &__position {
    display: flex;
    position: absolute;
    z-index: 4;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    bottom: -2rem;
    right: 0;
    height: fit-content;
  }
}
.mob-button:hover {

  outline-color: #e6bc18;
  outline-offset: 25px;
  background-color: #e6bc18;
  outline-color: rgba(255, 255, 255, 0);
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  #mobile {
    margin-top: 0;
    height: 50rem;
  }
  .swiper {
    height: 41rem;
  }
  .swiper .swiper-slide.active {
    height: 23rem !important;
  }
  .swiper .swiper-slide {
    height: 9rem !important;
  }

  .swiper {
    .prev {
      width: 10rem;
    }
    .next {
      width: 10rem;
    }
    .prev,
    .next {
      svg {
        width: 5rem;
        height: 3rem;
      }
    }
  }

  .mobile {
    width: 100%;
    height: 18rem;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 17px;
    }

    &__img {
      width: 12rem;
      height: 11rem;
    }

    &__container {

      & a {
        > img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .mob-button {
    font-size: 1.5rem;
    border-radius: 1rem;
    width: 28rem;
    height: 3.5rem;

    &__position {
      display: flex;
      justify-content: center;
      bottom: -1.6rem;  
    }
  }
}

@media only screen and (max-width: 1023px) {
  #mobile {
    margin-top: 0;
    height: 50rem;

    &__title {
      font-size: 2rem;
    }
  }
  .swiper {
    width: 100%;
    height: 42rem;
  }
  .swiper .swiper-slide.active {
    height: 24rem !important;
  }
  .swiper .swiper-slide {
    height: 5rem !important;
  }

  .swiper {
    .prev {
      width: 10rem;
    }
    .next {
      width: 10rem;
  
    }
    .prev,
    .next {
      svg {
        width: 5rem;
        height: 3rem;
      }
    }
  }

  .mobile {
    width: 100%;
    height: 18rem;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 17px;
    }

    &__img {
      width: 12rem;
      height: 11rem;
    }

    &__container {

      & a {
        > img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .mob-button {
    width: 23rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  #mobile {
    height: 27rem;

    &__title {
      font-size: 1rem;
    }
  }
  .swiper {
    width: 100%;
    height: 300px;
  }
  .swiper .swiper-slide.active {
    height: 10rem !important;
  }
  .swiper .swiper-slide {
    height: 5rem !important;
  }

  .swiper {
    .prev,
    .next {
      svg {
        width: 5rem;
        position: relative;
        height: 3rem;
        top: 40px;
      }
    }
  }

  .mobile {
    width: 100%;
    height: 18rem;
    background-repeat: no-repeat;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__title {
      font-size: 17px;
    }
  }

  .mob-button {
    width: 14rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;

    &__position {
      bottom: -1.2rem;
    }
  }
  .swiper-wrapper {
    height: 16rem !important;
  }
}
