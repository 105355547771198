#development-cicle {
  height: 65rem;
  position: relative;
}

.observer-circle-trigger {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
}

.development-cicle {
  &__title {
    color: #fff;
    margin-top: 20px;
    font-size: 2.25rem;
    text-align: center;
  }

  &__main-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &__main-hexagon-container {
    height: 60rem;
    position: relative;
    width: 100%;
  }
  
  &__circle-content {
    --lines-thickness: 8px;
    display: none;
    align-items: center;
    height: 60px;
    position: relative;
    gap: 15px;

    &__circle-line {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border: var(--lines-thickness) solid #fff;
      border-radius: 50%;
    }

    &__circle {
      background-color: #b28e03;
      border-radius: 50%; 
      width: 75%;
      height: 75%;
    }

    .below-line {
      background-color: #b28e03;
      width: var(--lines-thickness);
      height: 60px;
      position: absolute;
      top: 60px;
      left: 27px;
    }

    &__info {
      color: white;
      font-size: 1.4rem;
      font-weight: 700;
    }

    &:last-of-type {
      .below-line {
        height: 90px;
        background: linear-gradient(#b28e03, #b28f0300);
      }
    }
  }

}

.hexagon-container {
  position: absolute;
  width: 200px;
  color: #fff;
  transition: .4s;

  &:hover {
    transform: scale(1.1);
  }

  .hexagon {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
  }
  &__content {
    position: absolute;
    z-index: 5;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  &__title, &__text  {
    text-align: center;
    font-weight: bold;
  }

  &__title {
    font-family: 'SF Pro Display';
    font-size: 2.05rem;
    margin-top: 30px;
  }

  &__text {
    width: 180px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 1rem;
  }

  .hexagon {
    width: 100%;
  }
}

.hexagon {
  position: absolute;
  &__size-1 {
    width: 92px;
  }

  &__size-2 {
    width: 150px;
  }

  &__size-3 {
    width: 275px;
  }
}

.hexagon-container-1 {
  top: 453px;
  left: 7px;
}

.hexagon-container-2 {
  top: 352px;
  left: 207px;
}

.hexagon-container-3 {
  top: 453px;
  left: 407px;
}

.hexagon-container-4 {
  top: 352px;
  left: 607px;
}

.hexagon-container-5 {
  top: 453px;
  left: 807px;
}

.hexagon-container-6 {
  top: 352px;
  left: 1007px;
}

.hexagon-1 {
  top: 134px;
  left: 80px;
}

.hexagon-2 {
  top: 654px;
  left: 6px;
}

.hexagon-3 {
  top: 154px;
  left: 363px;
}

.hexagon-4 {
  top: 774px;
  left: 427px;
}

.hexagon-5 {
  top: 77px;
  left: 639px;
}

.hexagon-6 {
  top: 583px;
  left: 564px;
}

.hexagon-7 {
  top: 187px;
  left: 1056px;
}

.hexagon-8 {
  top: 577px;
  left: 995px;
}

.hexagon-9 {
  top: 273px;
  left: 58px;
}

.hexagon-10 {
  top: 191px;
  left: 202px;
}

.hexagon-11 {
  top: 823px;
  left: 120px;
}

.hexagon-12 {
  top: 574px;
  left: 163px;
}

.hexagon-13 {
  top: 149px;
  left: 761px; 
}

.hexagon-14 {
  top: 658px;
  left: 845px;
}

.hexagon-15 {
  top: 79px;
  left: 1002px;
}

.hexagon-16 {
  top: 738px;
  left: 1012px;
}

@media only screen and (max-width: 1350px) { 
  #development-cicle { 
    height: 60rem;
  }

  .development-cicle {
    &__main-hexagon-container {
      top: -120px;
      transform: scale(.68);
    }
  }
  /* .hexagon-container {
    transform: scale(.8);
  }

  .hexagon {
    &__size-1,
    &__size-2,
    &__size-3 {
      transform: scale(.8);
    }
      
  } */
}

@media only screen and (max-width: 1023px) { 
  .development-cicle {
    &__main-wrapper {
      padding-left: 10px;
      justify-content: start;
      margin-top: 20px;
      padding-top: 50px;
    }

    &__circle-content {
      display: flex;
    }

    &__main-hexagon-container {
      display: none;
    }
    &__main-wrapper {
      display: flex;
      flex-direction: column;
      gap: 60px;
      padding-left: 25px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .development-cicle {
    &__title {
      font-size: 1.6rem;
    }
  }
}

@media only screen and (max-width: 500px) { 
  .development-cicle {
    &__main-wrapper {
      padding-left: 10px;
    }

    &__circle-content {
      &__info {
        font-size: 1rem;
        width: 70%;
      }
    }
  }
}

@keyframes testar {
  from{
    transform: translateY(0px);
  }
    
  to {
    transform: translateY(20px);
  }
}