#info {
  background: url(../assets/images/info-section-bg.png) no-repeat;
  background-size: 101% 100%;
  height: 90vh;
}

#info .info-container {
  position: relative;
  bottom: 60px;
}

.info-img {
  width: 22rem;
  height: 20rem;
}
.info {
  &__cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5rem;
    align-items: center;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 5rem;
      align-items: center;
    }
  }
  &__project {
    color: #fff;
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      & h2 {
        font-size: 2rem;
      }
    }
  }
  &__images {
    width: 5rem;
    height: 5rem;
  }
  &__text {
    width: 13rem;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.info__images.building {
  margin-left: 2rem;
}

@media only screen and (max-width: 1400px) {
  #info {
    height: 90vh;
  }
  
  #mobile {
    height: 90vh;
  }

  #mobile__title {
    color: #fff;
    text-align: center;
    padding: 40px;
    font-size: 1.5rem;
  }
}

// Para o caso de a tela estar curta a o width ainda grande
@media only screen and (max-height: 700px) {
  #info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 35rem;
  }

  .text {
    display: flex;
    justify-content: center;
  }

  .info {
    &__images {
      width: 4rem;
      height: 4rem;
    }
    &__project {
      font-size: 17px;
      &__container {
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;
    }
    &__text {
      width: 10rem;
      color: #fff;
      font-size: 9px;
    }
  }
  .info__project__container h2 {
    font-size: 1.5rem;
  }
  .info__text {
    font-size: 0.8rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-img {
    width: 20rem;
    height: 20rem;
  }

  .info__images.building {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1023px) {
  #info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 35rem;
    background-size: 180%;
    background-position: -100px;
  }

  .info {
    &__images {
      width: 7rem;
      height: 7rem;
    }
    &__project {
      color: #fff;
      font-size: 17px;
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    &__cards {
      gap: 1rem;

      &__container {
        flex-direction: column;
        gap: 20px;
      }
    }
    &__text {
      width: 13rem;
      color: #fff;
      text-align: center;
      font-size: 9px;
      font-weight: 700;
    }
  }  

  .info__project__container h2 {
    font-size: 2rem;
  }
  .info__text {
    font-size: 1rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
  }

  .info-img {
    width: 12rem;
    height: 12rem;
  }

  .info__images.building {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  #info {
    height: 33rem;
  }

  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .info {
    &__images {
      width: 6rem;
      height: 6rem;
    }
    
    &__cards {
      gap: 1rem;
    }
    &__text {
      width: 13rem;
      text-align: center;
      font-size: 9px;
    }
  }
  .info__project__container h2 {
    font-size: 2rem;
  }
  .info__text {
    font-size: .9rem;
  }
  .info__cards {
    width: 100%;
    margin-top: 1rem;
  }
  .info__cards__container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
  }

  .info-img {
    width: 10rem;
    height: 10rem;
  }

  .info__images.building {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 520px) {
  #info {
    background: url(../assets/images/info-section-bg-mobile.png) no-repeat;
    background-size: cover;
  }
}
