#info-aboutus {
  background: url(../assets/images/info-about-us-bg.png) no-repeat;
  background-size: 100% 100%;
  /* padding-top: 50px;   */
  height: 90vh;
  /* background-position: 0; */
}

#info-aboutus .info-container .info-text-content {
  font-weight: bold;
}


@media only screen and (max-height: 700px) {
  #info-aboutus {
    height: 35rem;
  }
}

@media only screen and (max-width: 1400px) {
  #info-aboutus .info-container .info-text {
    font-size: 1.8rem;
    width: 100%;
    max-width: 690px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  #info-aboutus {
    height: 35rem;
    background-size: 1440px 800px;
    background-position: -300px -163px;  
  }

}

@media only screen and (max-width: 767px) { 
  #info-aboutus {
    height: 33rem;
  }
}

@media only screen and (max-width: 500px) {
  #info-aboutus {
    background: url(../assets/images/info-about-us-bg-mobile.png)  no-repeat;  
    background-size: cover;
    background-position: 0;
  }
}